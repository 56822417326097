import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import CoinbaseBanner from '../../assets/images/coinbase/coinbase-feature.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Coinbase extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Asset Integration for a Leading Cryptocurrency Exchange Platform
                </div>
                <div className="subtitle section-margin">
                  Building on our Rosetta experience to integrate numerous assets and deliver
                  immense value
                </div>
                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Accelerate asset enablement by bringing in specialists to work with Rosetta
                      API specifications and quickly integrate assets with their ecosystem.
                    </p>
                  </div>
                  <div className="mini-card ">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Delivered Rosetta implementations for assets, Rosetta validations, and asset
                      integrations to speed up the client’s asset integration efforts.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>

                    <p className="desc">
                      In addition to providing our deep expertise, Tintash helped build capacity by
                      training the client’s internal teams on asset integration and Rosetta.
                    </p>
                  </div>
                </div>
                <div className="col-11 mr-auto ml-auto ">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      This US-incorporated client was one of the largest and most compliant asset
                      exchanges in the country. They were looking to onboard specialists who could
                      help support and speed up the company’s asset integration efforts.
                    </p>
                    <br />
                    <p className="description">
                      Although the client operated on a remote-first model, there were really very
                      few locations outside of the US, Canada, and Europe from where they allowed
                      their team to work from. However, the team was in the hunt of a very specific
                      skill set – expertise in Rosetta API specifications. Left with few team
                      members who fully understood how Rosetta worked, the client engaged Tintash
                      owing to our deep expertise in this area to help eliminate this resource
                      bottleneck.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      It took Tintash almost three months to get all the required security
                      clearances and access to the client’s systems and machines. Once we started,
                      our team surprised everyone by delivering a complete Rosetta implementation in
                      a mere three weeks. We also helped enable an asset in the quickest ever time
                      in the client’s entire experience of asset integrations.
                    </p>
                    <br />
                    <p className="description">
                      If our first rosetta implementation showcased our technical ability, our first
                      integration highlighted our capacity to collaborate with multiple teams and
                      stakeholders while keeping everyone focused on the common goal. Positively
                      surprised by our team’s performance, the client handed over the complete asset
                      integration to us.
                    </p>
                    <br />
                    <p className="description">
                      Our team did not just go above and beyond to deliver the complete end-to-end
                      integration, we did so while also training the client’s engineers. We also
                      interfaced with at least a dozen asset issuers and helped them resolve issues
                      in their Rosetta implementations for a smoother integration.
                    </p>
                    <br />
                    <p className="description">
                      The third challenge was validating the Rosetta implementations built by the
                      asset issuers for their respective blockchains while also working closely with
                      them to sort out the issues in those implementations. Rosetta being open
                      source doesn’t dictate a lot of those things to be required. However, since we
                      had worked on a complete end-to-end integration, our team foresaw those issues
                      and got them resolved before the actual integration kicked off. This helped
                      further speed up the integration process.
                    </p>
                    <br />
                    <p className="description">
                      At that point, the client’s executive and product team started looking up to
                      us to drive the optimization efforts in the asset integration pipeline. Our
                      team conducted the root cause analysis and identified Rosetta completeness as
                      still being the biggest bottleneck.
                    </p>
                    <br />
                    <p className="description">
                      For the next couple of months, we worked closely with the product team to
                      further solidify the Rosetta specifications, which were internal to our
                      client, and effectively share them with all the new assets we were validating
                      and integrating.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      To summarize, our first Rosetta implementation in a mere three weeks was very
                      well received and unlocked opportunities for us to work on the E2E Asset
                      Integration of numerous assets. Our team also worked closely with the asset
                      issuers to provide them a smooth onboarding experience while getting them to
                      deliver on our requirements.
                    </p>
                    <br />
                    <p className="description">
                      At the time of this writing (Q1 '23), Tintash continued to be the only team
                      contracted by the client to work as an integral part in their asset
                      integration. Our team members were all working on different assets with the
                      client engineers to provide their expertise and help speed up the multiple
                      asset additions.
                    </p>
                    <br />
                    <p className="description">
                      Another noteworthy contribution was our team’s squad-building efforts for the
                      client. We got the mandate to build a squad in the US and Canada. We not only
                      recruited and trained IC5 and IC4 engineers across North America but also
                      successfully onboarded them on our client platforms and systems to deliver yet
                      more value to the client.
                    </p>
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Coinbase;
